@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');

body, html {
    font-family: 'Red Hat Display', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}